import React,{useEffect, useState,useMemo} from 'react';
import { useInterval} from 'ahooks';

import './FormMain.scss';
import {navigate} from 'gatsby';
import Utils from '../../../utils/utils';
import Api from '@/api';
import md5 from 'md5';
import Succsss from './Success'; //报名成功
import MyEnroll from './MyEnroll';
import api from '../../../api';
import {ConfigProvider,DatePicker} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

const FomrMain=(props)=>{
  
  let type=0;
  let source=0;
  let editId=0;
  let refs=[];
  let sendLoading=0;




  const types=FORMITEMLIST.map(item=>item.type);

  const [typeIndex,setTypeIndex]=useState(0);
  const [tabType,setTabType]=useState('');
  const [codeTime,setCodeTime]=useState(0);
  const [intervalTime,setIntervalTime]=useState(null);
  const [formsClassName,setFormsClassName]=useState('');
  let dataSet=[];

  let query = Utils.urlQuery();


  // if(query['type']!=types[typeIndex]){
  //   setTypeIndex(types.indexOf(query['type']));
  // }
  //FORMITEMLIST[typeIndex]




  const changeType=(type,params)=>{
    setTypeIndex(type?types.indexOf(type):0);
    console.log('?type='+type+(params || ''))
    navigate('?type='+type+(params || ''));
  }

  const enrollById=(id)=>{
    Api.enrollDetail({id}).then(data=>{
      [].slice.call(document.querySelectorAll('#formDom [name]')).map(dom=>{
        const domName=dom.name;
        if(data[domName]){
          dom.value=data[domName];
        }
      })
    }).catch(err=>{
      if(err.errCode==603){
        toLogin();
      }
    })
  }

  
  const valid=(data,key)=>{
    let result=true;
    let msg='';
    const forms=FORMITEMLIST[typeIndex];
    forms.list.map(item=>{
      if(key && item.name!=key){
        return;
      }
      
      if(!item.name || (!item.valid && !item.maxLength && !item.minLength && !item.required))
        return;
      
      const value=data[item.name] || '';
      if(item.required){
        if(!value || !Utils.valid(value)){
          result=false;
          if(!msg)
            msg=item.placeholder;
          validBorder(item.name,true);
          return;
        }else{
          validBorder(item.name,false);
        }
      }



      if(item.minLength>value.length){
        if(!msg) 
          msg=item.label+'长度应不小于'+item.minLength+'位';
        result=false;
        //document.querySelector('#formDom [name='+item.name+']').style.borderColor='red';
        validBorder(item.name,true);
        return;
      }else{
        validBorder(item.name,false);
        //document.querySelector('#formDom [name='+item.name+']').style.borderColor='#DDDDDE';        
      }

      if(item.maxLength<value.length){
        if(!msg) 
          msg=item.label+'长度应不大于'+item.maxLength+'位';
        result=false;
        validBorder(item.name,true);
        //document.querySelector('#formDom [name='+item.name+']').style.borderColor='red';
        return;
      }else{
        validBorder(item.name,false);
        //document.querySelector('#formDom [name='+item.name+']').style.borderColor='#DDDDDE';        
      }     

      if(item.valid){
        for(let i=0;i<item.valid.length;i++){
          const validType=item.valid[i];
          if(!Utils.valid(value,validType)){
            if(!msg)
              msg=item.label+'格式不正确';
            result=false;
            validBorder(item.name,true);
            break;
          }else{
            validBorder(item.name,false);
          }
        }
      }
    })
    if(msg){
      Utils.message(msg);
    }
    return result;
  }

  const validBorder=(name,isError)=>{
    const dom=document.querySelector('#formDom [name='+name+']')
    if(isError){
      dom.className+=' error-valid';
    }else{
      dom.className=dom.className.replace(' error-valid','');
    }
  }

  const formButtonHander=(row)=>{
    const urlQuery=Utils.urlQuery();
    let data={};
    switch(row.type2){
      case 'changeType':
        //setTypeIndex(row.params?types.indexOf(row.params):0);
 

        
        changeType(type);
        //navigate(`${'?type='+row.params}`);
        break;
      case 'nextStep':
        [].slice.call(document.querySelectorAll('#formDom [name]')).map(item=>{
          data[item.name]=item.value;
        })
        if(!valid(data))
          return
        sessionStorage.tempData=JSON.stringify(data);
        setTypeIndex(types.indexOf(types.indexOf('enroll2')));

        source=urlQuery['source'] || sessionStorage.sourceType || 0;
        editId=urlQuery['editid'] || 0;

        changeType('enroll2',`&source=${source}&editid=${editId}`);

        break;
      case 'prevStep':
        setTypeIndex(types.indexOf(types.indexOf('enroll')));
        source=urlQuery['source'] || sessionStorage.sourceType || 0;
        editId=urlQuery['editid'] || 0;
        changeType('enroll',`&source=${source}&editid=${editId}`);
        break;

      case 'login':
        data={
          systemCode: "adviser",
          isRemember:0,
        };
        [].slice.call(document.querySelectorAll('#formDom [name]')).map(item=>{
          data[item.name]=item.value;
        })

        if(!valid(data))
          return;
        data.pwd=data.pwd?md5(data.pwd):'';
        api.login(data).then(res=>{
          // document.cookie='match_token='+res.token+';adviser='+data.userName+'';
          // document.cookie='adviser='+data.userName+'';
          Utils.setCookies('match_token',res.token);
          Utils.setCookies('adviser',data.userName);
          Utils.setCookies('adviser_phone',res.phone);
          props.changeUser && props.changeUser();
          Utils.message({message:'您已成功登陆',type:'success'});
          if(sessionStorage.oldType){
            console.log('准备报名',sessionStorage.oldType)
            changeType(sessionStorage.oldType);
          }
          else{
            console.log('准备登陆');
            changeType('myEnroll');
          }
          if(sessionStorage.tempData)
            sessionStorage.tempData='';
          sessionStorage.oldType='';
        }).catch(err=>{
          err.message && Utils.message({message:err.message,type:'error'});
        });
        break;
      case 'reset':
        validSmsCode();
        break;
      case 'resetConfirm':
        resetPassword();
        break;
      case 'enroll':
        {
          if(sendLoading){
            return;
          }
          let data={};
          if(sessionStorage.tempData)
            data = JSON.parse(sessionStorage.tempData);
          [].slice.call(document.querySelectorAll('#formDom [name]')).map(item=>{
            data[item.name]=item.value;
          })
          if(!valid(data))
            return;
          editId=urlQuery['editid'];
          if(editId && editId!='0')
            data.id=editId;
          if(sessionStorage.sourceType)
            data.sourceType=sessionStorage.sourceType;
          sendLoading=1;
          api.savelEnroll(data).then(res=>{
            sessionStorage.tempData='';
            //sessionStorage.sourceType='';
            //navigate('?type=success');
            changeType('success');
          }).catch(err=>{
            sendLoading=0;
            if(err.errCode==603)
              toLogin();
          })
        }
        break;
    }
  }

  const sendSmsCode=()=>{
    if(codeTime)
      return;
    const phone=document.querySelector('#formDom [name=phone]').value;
    if(!phone)
      return Utils.message({type:'error',message:'请输入手机号码'});
    if(!valid(phone,'mobile'))
      return Utils.message({type:'error',message:'请输入正确的手机号码'});

    setCodeTime(60);
    setIntervalTime(1000);

    Api.sendPhone({phone,systemCode:'adviser'}).then(res=>{

    }).catch(err=>{});
  }

  const toLogin=()=>{
    Utils.clearCookies('match_token');
    Utils.clearCookies('adviser');
    Utils.clearCookies('adviser_phone');
     // window.document.cookie='match_token=;';
    //navigate('?type=login')
    changeType('login');
    props.changeUser && props.changeUser();
  }

  const validSmsCode=()=>{
    const phone=document.querySelector('#formDom [name=phone]').value;
    const validateCode=document.querySelector('#formDom [name=validateCode]').value;
    Api.validatePhone({phone,validateCode}).then(res=>{
      sessionStorage.confirmForget=JSON.stringify({phone,validateCode});
      changeType('confirm');
      //navigate('?type=confirm');
    }).catch(err=>{
      return Utils.message({type:'error',message:err.message});

    })
  }

  const resetPassword=()=>{
    //const phoneNum=document.querySelector('#formDom [name=phone]').value;
    //const identifyCode=document.querySelector('#formDom [name=validateCode]').value;
    if(!sessionStorage.confirmForget)
      return;
    const {phone,validateCode}=JSON.parse(sessionStorage.confirmForget);
    const newPwd=document.querySelector('#formDom [name=newPassword]').value;
    const confirmPwd=document.querySelector('#formDom [name=password]').value;
    if(!newPwd || !confirmPwd){
      Utils.message({type:"error",message:'请输入密码'});
      return;
    }
    if(newPwd!=confirmPwd){
      Utils.message({type:"error",message:'两次输入密码不一致'});
      return;
    }
    const systemCode = "adviser";
    const pwd=md5(newPwd);
    Api.resetPassword({phoneNum:phone,identifyCode:validateCode,systemCode,pwd}).then(res=>{
      Utils.message({type:'success',message:'密码已经修改，即将返回登录…',delay:4000});

      setTimeout(()=>{
        toLogin();
      },4000)
      
    }).catch(err=>{

    })
  }

  useEffect(()=>{
    
    const urlQuery=Utils.urlQuery();

    setTypeIndex(types.indexOf(urlQuery['type']));
    
    window.addEventListener("popstate", function(e) {
      const urlQuery=Utils.urlQuery();
      if(urlQuery['type']){
        source=urlQuery['source'] || sessionStorage.sourceType || 0;
        editId=urlQuery['editid'] || 0;
        let url=(source?('&source='+source):'')+editId?('&editid='+editId):'';
        changeType(Utils.urlQuery()['type'],url);
      }
    })

  },[])



  useEffect(()=>{
    

    const urlQuery=Utils.urlQuery();
    if(urlQuery && urlQuery['type']){
      type=urlQuery['type'];
      source=urlQuery['source'] || sessionStorage.sourceType || 0;
      editId=urlQuery['editid'] || 0;
  
      if(source)
        sessionStorage.sourceType=source;
    }
    
    const token=Utils.getCookies('match_token');
    if(['login','forget','confirm'].indexOf(type)==-1  && !token){
      //navigate('?type=login');
      changeType('login');
      sessionStorage.oldType=type;
    }else if(type=='enroll2' && !(sessionStorage.tempData)){
      sessionStorage.oldType=type;
      changeType('enroll');
      //navigate('?type=enroll');
    } 

    if(type=='enroll' && sessionStorage.tempData){
      const data=JSON.parse(sessionStorage.tempData);
      [].slice.call(document.querySelectorAll('#formDom [name]')).map(dom=>{
        const domName=dom.name;
        if(data[domName])
          dom.value=data[domName];
      })
    }
    if(editId && editId!='0'){
      enrollById(editId);
    }
  
    //setTypeIndex(typeIndex);
    let typeIndexDefault=type?types.indexOf(type):0;

    if(typeIndexDefault!=typeIndex){
      setTypeIndex(typeIndexDefault);
    }

  },[typeIndex])

  useInterval(()=>{
    if(codeTime>0)
      setCodeTime(codeTime-1);
    else
      setIntervalTime(null);
    
  },intervalTime);




  

  const tabDom = useMemo(() =>{
    const forms=FORMITEMLIST[typeIndex];
    const tabs=(forms && forms.tabs && forms.tabs.length>0)? forms.tabs:[{label:'登录',type:'',tabs:[]}];
    const typeName= (forms && forms.type)?forms.type:'';
    return <div className={`tabs ${typeName+'-tabs'}`}>
      {tabs.map((item,index)=><span className="white-hover" onClick={e=>{item.type && changeType(item.type)}} key={index}>{item.label}</span>)}
    </div>
  },[typeIndex])

  const contentDom = useMemo(()=> {
    const forms=FORMITEMLIST[typeIndex];
    if(!forms){
      return <></>;
    }
    setFormsClassName((forms && forms.className) || '');
    let contentDom='';
    if(forms.type=='success'){
      contentDom=<Succsss changeType={changeType}/>
    }else if(forms.type=='myEnroll'){
      contentDom=<MyEnroll toLogin={toLogin} changeType={changeType}/>
    }else{
      contentDom=forms.list.map((item,index)=>(
        <div className={`form-item ${item.type || 'empty'}`} key={item.name || index}>
          {(item.type=='input' || item.type=='textarea') && <label className={item.required?'required':''}>{item.label}</label>}
          { item.type=='input'
            ? <span className={item.sms?"sms-box":""}>
              {
                item.type2=='date'?
                <ConfigProvider locale={zhCN}>
                  <DatePicker ref={ref=>refs[item.name]=ref} className={'andt-style'} locale={zhCN} name={item.name} onChange={e=>{}}/>
                </ConfigProvider>
                :
                <input onInput={e=>{}} name={item.name} type={item.type2?item.type2:''} placeholder={item.placeholder} />
              }
              {item.sms && <a onClick={e=>sendSmsCode()}>{intervalTime?`重新发送(${codeTime})`:'发送验证码'}</a>}
              </span>
            : item.type=='textarea'
              ? <span><textarea name={item.name} placeholder={item.placeholder} ></textarea></span>
              : item.type=='button'
                ? <button className="white-hover" type="button" onClick={e=>{formButtonHander(item)}}>{item.label}</button>
                : item.type=='link'
                  ? <a onClick={e=>{item.type && changeType(item.to)}}>{item.label}</a>
                  : item.type=='array'
                    ? item.list.map((item2,index2)=>(
                        item2.type=='button'
                          ? <button key={index2} className="white-hover" type="button" onClick={e=>{formButtonHander(item2)}}>{item2.label}</button>
                          : <span key={index2}></span>
                      ))
                    : <p style={{marginBottom:item.margin || ''}} className={item.className}>{item.label}</p>
          }
        </div>
      ))
    }
    return contentDom;
  }, [typeIndex,codeTime])





  return <div className={`FormMain  ${formsClassName}`}>      
      {tabDom}
      <form id="formDom" className="content">
          {contentDom}
      </form>
  </div>
}


export default FomrMain;


const FORMITEMLIST=[
  {
    type:'',className:'',tabs:[],list:[

    ]
  },
  //登录
  {
    type:'login',className:'login',tabs:[],list:[

      {label:'账号',placeholder:'请输入账号',name:'userName',type:'input',required:true,minLength:2,maxLength:20},
      {label:'密码',placeholder:'请输入密码',name:'pwd',type:'input',required:true,type2:'password'},
      {label:'忘记密码？',name:'forget',type:'link',to:'forget'},
      {label:'登录',name:'loginbtn',type:'button',type2:'login'},
      {label:'请使用RAYS编辑账号登录'},
      {label:'如暂无RAYS编辑账号，请联系027-87689225（雷老师）进行开通。'}
    ]
  },

  //忘记密码
  {
    type:'forget',tabs:[
      {label:'登录',type:'login'},
      {label:'密码找回',type:'forget'},
    ],
    list:[
      {label:'手机号',placeholder:'请输入11位手机号',required:true,name:'phone',valid:['moblie'],type:'input'},
      {label:'验证码',placeholder:'请输入验证码',required:true,name:'validateCode',type:'input',type2:'number',sms:true},
      {label:'',name:"nolink",type:'link'},
      {label:'下一步',name:'stepbtn',type:'button',type2:'reset',params:'confirm'},
      {label:'请使用RAYS编辑账号登录'},
      {label:'如暂无RAYS编辑账号，请联系027-87689225（雷老师）进行开通。'}
    ]
  },

  //确认密码
  {
    type:'confirm',tabs:[
      {label:'登录',type:'login'},
      {label:'密码找回',type:'confirm'},
    ],
    list:[
      {label:'新密码',placeholder:'请输入密码',required:true,name:'newPassword',type:'input',type2:'password'},
      {label:'确认新密码',placeholder:'请输入密码',required:true,name:'password',type:'input',type2:'password'},
      {label:'',name:"nolink",type:'link'},
      {label:'确定',name:'confirmPassword',type:'button',type2:'resetConfirm'},
      {label:'请使用RAYS编辑账号登录'},
      {label:'如暂无RAYS编辑账号，请联系027-87689225（雷老师）进行开通。'}
    ]
  },

  //立即报名
  {
    type:'enroll',tabs:[
      {label:'立即报名',type:'enroll'},
      {label:'我的报名',type:'myEnroll'}
    ],
    className:'long-label',
    list:[
      {label:'工作单位',placeholder:'请填写工作单位',required:true,name:'companyName',type:'input',maxLength:100},
      {label:'姓名',placeholder:'请填写参赛者姓名',required:true,name:'contestantName',type:'input',maxLength:50},
      {label:'团队其他成员姓名',placeholder:'请填写团队其他成员姓名',required:true,name:'teamMemberName',type:'input',maxLength:255},
      {label:'手机号码',placeholder:'请填写参赛者手机号码',required:true,name:'phone',valid:['mobile'],type:'input',type2:'number',maxLength:40},
      {label:'电子邮箱',placeholder:'请填写电子邮箱',name:'email',valid:['email'],required:true,type:'input',maxLength:50},
      {label:'微信号',placeholder:'请填写微信号',name:'wechatNo',required:true,type:'input',maxLength:50},
      {label:'RAYS编辑端账号',placeholder:'请填写RAYS编辑端账号',required:true,name:'adviserAccount',type:'input',maxLength:50},
      {label:'身份证号',placeholder:'请填写参赛者身份证号',required:true,name:'idCard',valid:['idCard'],type:'input',maxLength:50},
      {label:'注册责任编辑/出版职业资格证书编号',placeholder:'请填写证书编号',required:true,name:'adviserCertificateNo',type:'input',maxLength:100},
      {label:'通讯地址',placeholder:'请填写通讯地址',required:true,name:'address',type:'input',maxLength:255},
      {label:'',margin:'205px'},
      {label:'下一步',name:'stepbtn',type:'button',type2:'nextStep',params:'enroll2'}
    ]
  },
  //立即报名2
  {
    type:'enroll2',tabs:[
      {label:'立即报名',type:'enroll2'},
      {label:'我的报名',type:'myEnroll'}
    ],
    className:'middle-label',
    list:[
      {label:'出版物名称',placeholder:'请填写出版物名称',required:true,name:'bookName',type:'input',maxLength:255},
      //{label:'ISBN',placeholder:'请填写ISBN',required:true,name:'isbn',type:'input',maxLength:100},
      //{label:'出版物类型',placeholder:'请填写出版物类型',required:true,name:'bookType',type:'input',maxLength:50},
      {label:'印刷时间',placeholder:'请填写印刷时间',name:'printDate',required:true,type:'input',type2:'date'},
      {label:'发行量',placeholder:'请填写发行量',name:'publishNum',required:true,type:'input',type2:'number',maxLength:6},
      //{label:'出版物简介',placeholder:'出版物简介',required:true,name:'bookIntroduction',type:'textarea',maxLength:1000},
      //{label:'作者及编辑简介',placeholder:'作者及编辑简介',required:true,name:'adviserIntroduction',type:'textarea',maxLength:1000},
      {label:'',margin:'30px'},
      //{label:'提交',name:'stepbtn',type:'button',type2:'enroll',params:''},
      {label:'',name:'array',type:'array',list:[
        {label:'上一步',name:'stepbtn',type:'button',type2:'prevStep',params:'enroll'},
        {label:'提交',name:'stepbtn',type:'button',type2:'enroll',params:''},
      ]}
    ]
  },
  //报名成功
  {
    type:'success',tabs:[
      {label:'立即报名',type:'enroll2'},
      {label:'我的报名',type:'myEnroll'}
    ],
    className:'success',list:[],
  },
  {
    type:'myEnroll',tabs:[
      {label:'立即报名',type:'enroll2'},
      {label:'我的报名',type:'myEnroll'}
    ],
    className:'success',list:[],
  },
]